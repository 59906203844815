.container.loading {
  position: absolute;
  top: 0;
  width: 100vw;
  max-width: none;
  height: 100vh;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.2);
}

.bs-stepper .content {
  padding: 25px 0;
}

.container.loading > div {
  display: block;
  position: fixed;
  top: calc(50% - (31px / 2));
  right: calc(50% - (31px / 2));
}

.active .bs-stepper-circle {
  background-color: #ffc107!important;
  color: #212529;
}

.active .bs-stepper-label {
  color: #212529;
}